
import { defineComponent } from "vue";
import Greenhouse from "@/components/FARMacy/Greenhouse.vue";
import Pickup from "@/components/FARMacy/FARMacyPickup.vue";
import BlueCrayz from "@/components/FARMacy/BlueCrayz.vue";
import Garuda from "@/components/FARMacy/Garuda.vue";
import RDLabs from "@/components/FARMacy/RDLabs.vue";
import Retail from "@/components/FARMacy/Retail.vue";

export default defineComponent({
  components: {
    Greenhouse,
    Pickup,
    BlueCrayz,
    Garuda,
    RDLabs,
    Retail,
  },
});
