
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      bluecrayz: {
        title: "The Blue Crayz",
        subtitle: "Fresh-to-you Restaurant and Juice Bar",
        subColor: "var(--bluecrayz)",
        map: "BlueCrayz.png",
        paragraph: {
          p1: "The mission of the Blue Crayz restaurant is to provide healthy alternatives with a menu that is focused on nutrient-dense vegetables, fruits, herbs, and select proteins.",
          p2: "The Blue Crayz is complimented by a juice bar with specialized nutrient-dense and enzyme-rich juice supplements.",
        },
        logo: "Blue_Crayz.svg",
        images: {
          img1: "img1_small.jpg",
          img2: "img2_small.jpg",
          img3: "img3_small.jpg",
          img4: "img4_small.jpg",
        },
        link: "/farmacy/bluecrayz",
      },
    };
  },
});
