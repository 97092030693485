
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      rdlabs: {
        title: "R&amp;D Labs",
        subtitle: "Research &amp; Development",
        subColor: "var(--rdlabs)",
        map: "RDLabs.png",
        paragraph: {
          p1: "Each FARMacy is structured with two R&amp;D labs. Their purpose is to deliver the third-party research needed to enhance the understanding and education surrounding food is medicine. The R&amp;D labs facilitate the third-party validation for nutrient-density of ECSIA<sup>&reg;</sup> grown products.",
        },
        images: {
          img1: "img1_small.jpg",
          img2: "img2_small.jpg",
          img3: "img3_small.jpg",
          img4: "img4_small.jpg",
        },
        link: "/farmacy/rdlabs",
      },
    };
  },
});
