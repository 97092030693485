import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FarmComponent = _resolveComponent("FarmComponent")!

  return (_openBlock(), _createBlock(_component_FarmComponent, {
    title: _ctx.retail.title,
    subtitle: _ctx.retail.subtitle,
    subColor: _ctx.retail.subColor,
    paragraph: _ctx.retail.paragraph,
    images: _ctx.retail.images,
    map: _ctx.retail.map,
    link: _ctx.retail.link
  }, null, 8, ["title", "subtitle", "subColor", "paragraph", "images", "map", "link"]))
}