
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      retail: {
        title: "Retail &amp; Tours",
        subtitle: "Information Lobby",
        subColor: "var(--retail)",
        map: "RetailTours.png",
        paragraph: {
          p1: "The information lobby located within the FARMacy facility contains an area where value-add products and other retail items are sold. These include herbs, essential oils, tinctures, salves, and more.",
        },
        images: {
          img1: "img1_small.jpg",
          img2: "img2_small.jpg",
          img3: "img3_small.jpg",
          img4: "img4_small.jpg",
        },
        link: "/farmacy/retail",
      },
    };
  },
});
