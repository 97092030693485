import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FarmComponent = _resolveComponent("FarmComponent")!

  return (_openBlock(), _createBlock(_component_FarmComponent, {
    class: "grey-bg",
    title: _ctx.bluecrayz.title,
    subtitle: _ctx.bluecrayz.subtitle,
    subColor: _ctx.bluecrayz.subColor,
    paragraph: _ctx.bluecrayz.paragraph,
    logo: _ctx.bluecrayz.logo,
    images: _ctx.bluecrayz.images,
    map: _ctx.bluecrayz.map,
    link: _ctx.bluecrayz.link
  }, null, 8, ["title", "subtitle", "subColor", "paragraph", "logo", "images", "map", "link"]))
}